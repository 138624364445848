import { cloneElement, useState } from 'react';
import { Link } from '@remix-run/react';
import { useThrottle } from 'ahooks';
import type { EnquiryModalProps as AriaEnquiryModalProps } from '@archipro-design/aria';
import { Caption, pxToRem } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { Image } from '@archipro-design/aria';
import { EnquiryModal as AriaEnquiryModal } from '@archipro-design/aria';
import LoginSignUpModal from '~/modules/user/component/login-signup-modal/LoginSignUpModal';
import type { LoginFlow } from '~/modules/user/hook/use-login-flow';
import { usePublicUser } from '~/modules/user/hook/use-public-user';
import { useEnquirySession } from '../../hook/use-enquiry-session';
import type {
    EnquiryFormProps,
    EnquiryProfessional,
} from '../enquiry-form/EnquiryForm';
import EnquiryForm from '../enquiry-form/EnquiryForm';
import * as S from './EnquiryModal.style';
import BottomDrawer from '~/modules/detail/components/bottom-drawer/BottomDrawer';
import { useABTestValue } from '~/modules/root/hook/use-growthbook';
import { LoginEnquiryInline } from '../login-enquiry-inline/LoginEnquiryInline';
import { isFacebookBrowser } from '~/modules/detail/hooks/useFacebookAutofill';

interface EnquiryModalProps extends EnquiryFormProps {
    professional: EnquiryProfessional;
    trigger?: JSX.Element;
    modalProps?: AriaEnquiryModalProps;
    drawer?: boolean;
    open?: boolean;
    onOpenChange?: (open: boolean) => void;
    siteTreeID?: number;
    submitButtonText?: string;
    messageBodyPlaceholder?: string;
    onViewProfile?: () => void;
    customHeading?: string;
}

const EnquiryModal = (props: EnquiryModalProps) => {
    const {
        professional,
        branchOptions,
        modalProps,
        trigger,
        drawer,
        open: shouldOpen,
        onOpenChange,
        siteTreeID,
        submitButtonText,
        messageBodyPlaceholder,
        relatedItem,
        customHeading,
    } = props;

    const enquiryVariantControl = useABTestValue('upgraded-enquiry-flow');
    const isControlVariant = enquiryVariantControl === 'control';

    const [localOpen, setLocalOpen] = useState(false);
    const [form, setForm] = useState<'login-sign-up' | 'enquiry'>('enquiry');
    const { publicUser, setPublicUser } = usePublicUser('enquiry');
    const { handlePostEnquiryCancel } = useEnquirySession();
    const [facebookBrowserThrottle, setFacebookBrowserThrottle] =
        useState(isFacebookBrowser());

    const flow: LoginFlow =
        publicUser?.type === 'new'
            ? 'post-enquiry-sign-up'
            : 'post-enquiry-login';

    const { css } = useStyles({
        logoFallback: professional.LogoBackgroundColor,
        isControlVariant,
        flow,
    });

    const handlePostEnquiryClose = () => {
        if (form === 'login-sign-up' && flow === 'post-enquiry-sign-up') {
            handlePostEnquiryCancel();
        }
    };

    const open = shouldOpen ?? localOpen;

    const setOpen = (open: boolean) => {
        onOpenChange ? onOpenChange(open) : setLocalOpen(open);
    };

    const renderEnquiryContent = () => {
        return (
            <>
                <div className={css(S.Header)}>
                    <div className={css(S.ProfessionalLogo)}>
                        <Image
                            as={Link}
                            to={professional.Link}
                            width={100}
                            height={100}
                            src={professional.CompanyLogo ?? ''}
                            loading="eager"
                            layout="raw"
                            alt={professional.Title || 'Company Logo'}
                        />
                    </div>

                    <div className={css(S.HeaderRight)}>
                        <Caption
                            variant="01"
                            className={css(S.HeaderRightTitle)}
                        >
                            {customHeading ?? 'Make an Enquiry'}
                        </Caption>
                    </div>
                </div>
                {isControlVariant ? (
                    <EnquiryForm
                        professional={professional}
                        relatedItem={relatedItem}
                        branchOptions={branchOptions}
                        onSuccess={() => {
                            setOpen(false);
                            setFacebookBrowserThrottle(false);
                        }}
                        onFailure={(publicUser) => {
                            setPublicUser(publicUser);
                            setForm('login-sign-up');
                            setOpen(true);
                        }}
                        source={'EnquiryModal'}
                        siteTreeID={siteTreeID}
                        messageBodyPlaceholder={messageBodyPlaceholder}
                        submitButtonText={submitButtonText}
                    />
                ) : (
                    <div className={css(S.LoginEnquireModal)}>
                        <LoginEnquiryInline
                            professional={professional}
                            source={'EnquiryModal'}
                            enquiryVariantControl={enquiryVariantControl}
                            submitButtonText={submitButtonText}
                            messageBodyPlaceholder={messageBodyPlaceholder}
                            siteTreeID={siteTreeID}
                            isEnquiryModal
                            onSuccess={() => {
                                setOpen(false);
                            }}
                            itemType={relatedItem?.type ?? 'professional'}
                        />
                    </div>
                )}
            </>
        );
    };

    // It's pretty hard managing the state of this drawer as we have both
    // controlled (openProp) and uncontrolled (localOpen) state seperated out.
    // This is a workaround to avoid changing existing logic.
    // The reason of this change is that the drawer shouldn't be rendered if it's not open.
    // The throttle is to give time to the animation to end before destroying the component.
    const isOpen = localOpen || shouldOpen;
    const throttledClosed = useThrottle(isOpen || facebookBrowserThrottle, {
        wait: 1000,
    });

    return (
        <>
            {form === 'enquiry' && drawer ? (
                <>
                    {trigger &&
                        cloneElement(trigger, {
                            onClick: () => {
                                if (
                                    typeof trigger.props.onClick === 'function'
                                ) {
                                    // fire the original onClick if any
                                    trigger.props.onClick();
                                }
                                setOpen(true);
                            },
                        })}
                    {(isOpen || throttledClosed) && (
                        <BottomDrawer
                            open={open}
                            toggleModal={(value: boolean) => setOpen(value)}
                            title={'Enquire'}
                        >
                            <div className={css(S.BottomDrawerContent)}>
                                {renderEnquiryContent()}
                            </div>
                        </BottomDrawer>
                    )}
                </>
            ) : (
                <AriaEnquiryModal
                    content={renderEnquiryContent()}
                    headerAction={{
                        color: 'dark',
                        onClick: () => setOpen(false),
                    }}
                    className={css(S.EnquiryModal)}
                    footer={[]}
                    onOpen={() => setOpen(true)}
                    onConfirm={() => setOpen(false)}
                    onCancel={() => setOpen(false)}
                    open={open}
                    trigger={trigger}
                    variables={{
                        rootWidth: pxToRem(900),
                        rootMaxHeight: 'unset',
                    }}
                    {...modalProps}
                />
            )}
            {form === 'login-sign-up' && (
                <LoginSignUpModal
                    login={{
                        open,
                        onOpen: () => setOpen(true),
                        onCancel: () => {
                            handlePostEnquiryClose();
                            setOpen(false);
                        },
                        onAuthSuccess: () => setOpen(false),
                        onConfirm: () => setOpen(false),
                        headerAction: {
                            onClick: () => {
                                handlePostEnquiryClose();
                                setForm('enquiry');
                            },
                        },
                        flow,
                        defaultPublicUser: publicUser,
                        authSource: 'enquiry',
                    }}
                    forgotPassword={{
                        open,
                        onCancel: () => setOpen(false),
                        onConfirm: () => setOpen(false),
                    }}
                />
            )}
        </>
    );
};

export default EnquiryModal;
