import type { StyleRule } from '@archipro-design/aria';
import { dialogClassName } from '@archipro-design/aria';
import { pxArrayToRem } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import { getScrollbarStyle } from '@archipro-design/aria';
import { enquiryModalSlotClassNames } from '@archipro-design/aria';
import { labelClassName } from '@archipro-design/aria';
import {
    dialogFooterClassName,
    dialogSlotClassNames,
} from '@fluentui/react-northstar';
import { COMPANY_INFO_HEADER_MARGIN } from '../../constants';

export const Modal: StyleRule = ({ theme }) => {
    return {
        ...getScrollbarStyle(theme, '0'),
        [`& .${enquiryModalSlotClassNames.header}`]: {
            [`& .${labelClassName}`]: {
                color: theme.siteVariables.colors.charcoal[250],
            },
        },
    };
};

export const EnquiryModal: StyleRule = ({ flow }) => ({
    [`&.${dialogClassName}`]: {
        padding: pxToRem(74),
        ...(flow === 'post-enquiry-login' && {
            padding: pxToRem(64),
        }),
        [`& .${dialogFooterClassName}`]: {
            display: 'none',
            margin: pxToRem(0),
        },
        [`& .${dialogSlotClassNames.content}`]: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
    },
});

export const Header: StyleRule = ({ isControlVariant }) => {
    return {
        marginBottom: isControlVariant
            ? pxToRem(COMPANY_INFO_HEADER_MARGIN)
            : 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: 'fit-content',
        [`& .${dialogSlotClassNames.content}`]: {
            height: 'fit-content',
        },
    };
};

export const ProfessionalLogo: StyleRule<{ logoFallback?: string | null }> = ({
    theme,
    logoFallback,
}) => {
    return {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor:
            logoFallback ?? theme.siteVariables.naturalColors.white['FFF'],
        width: pxToRem(100),
        height: pxToRem(100),
        [`& img`]: {
            objectFit: 'contain',
            objectPosition: 'center',
            width: '100%',
            height: '100%',
        },
    };
};

export const HeaderRight: StyleRule = () => {
    return {
        flex: 1,
        display: 'flex',
        alignSelf: 'stretch',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingLeft: pxToRem(20),
    };
};

export const HeaderRightTitle: StyleRule = () => {
    return {};
};

export const HeaderRightCaption: StyleRule = () => {
    return {
        paddingTop: pxToRem(14),
        opacity: 0.75,
    };
};

export const BottomDrawerContent: StyleRule = () => {
    return {
        padding: pxArrayToRem([0, 18]),
        marginBottom: pxToRem(60),
    };
};

export const LoginEnquireModal: StyleRule = () => ({
    display: 'flex',
    [`& >div`]: {
        flexGrow: 2,
    },
});

export const TestButton: StyleRule = () => ({
    position: 'fixed',
    top: pxToRem(20),
    left: pxToRem(20),
});
